import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BooksPage from './pages/BooksPage';
import BiographyPage from './pages/BiographyPage';
import ContactPage from './pages/ContactPage';
import Navigation from './components/Navigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const theme = createTheme({
    direction: 'rtl', // Setze die Richtung auf RTL
    typography: {
        fontFamily: 'SansArabic, sans-serif',
        allVariants:{lineHeight:2}
    },
    shape: {
        borderRadius: 20,
    },
    palette: {
        primary: {
            main: '#2A4B7C',
        },
        secondary: {
            main: '#333333',
        },
        background: {
            default: 'linear-gradient(45deg, #333333, #2A4B7C)',
            paper: '#14213d',
        },
        text: {
            primary: '#ffffff',
            secondary: '#bbbbbb',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    fontFamily: 'SansArabic, Arial, sans-serif',
                    background: '#14213d',
                },
            },
        },
    },
});


function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Navigation />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/books" element={<BooksPage />} />
                    <Route path="/biography" element={<BiographyPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>

    );
}

export default App;
