import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    useTheme,
    Box,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navigation = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleNavigateHome = () => {
        navigate('/');
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = [
        { text: 'صفحه اصلی', path: '/' },
        { text: 'کتاب ها', path: '/books' },
        { text: 'درباره نویسنده', path: '/biography' },
        { text: 'برقراری ارتباط', path: '/contact' },
    ];

    const drawer = (
        <Box
            sx={{ width: 200, mt: '30%' }}
            dir="rtl"
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem button key={item.text} onClick={() => {navigate(item.path); handleDrawerToggle();}}>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>

    );

    return (
        <Box sx={{ flexGrow: 1, width: '100%', position: 'sticky', top: 15, zIndex: 1100 }}>
            <AppBar position="static" color="primary" elevation={2} sx={{ borderRadius: 3, margin: 'auto', maxWidth: '100%', width: isMobile ? '90%' : '60%' }}>
                <Toolbar variant="dense">
                    {isMobile ? (
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, textAlign: 'center', cursor: 'pointer' }} // Cursor-Stil hinzufügen
                                onClick={handleNavigateHome} // Klick-Handler hinzufügen
                            >
                                وبسایت رسمی نسرین قربانی
                            </Typography>
                        </>
                    ) : (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {menuItems.map((item) => (
                                <Button key={item.text} color="inherit" onClick={() => navigate(item.path)} sx={{ marginX: 2 }}>
                                    {item.text}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            {isMobile && (
                <Drawer
                    anchor="right"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                >
                    {drawer}
                </Drawer>
            )}
        </Box>
    );
};

export default Navigation;
