import React from 'react';
import { Grid, Card, CardContent, Typography, Link } from '@mui/material';

const ContactPage = () => {
    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                برقراری ارتباط
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                آدرس صفحات اجتماعی
                            </Typography>
                            <Typography variant="body2" color="text.primary" sx={{ mt: 2 }}>
                                مرا در اینستاگرام دنبال کنید
                            </Typography>
                            <Link href="https://www.instagram.com/nasringh37/?hl=de" sx={{ display: 'block' }}>Instagram</Link>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default ContactPage;
