import React from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardMedia,
    Container,
    useTheme,
    useMediaQuery,

} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const HomePage = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Container sx={{ mt: 4, mb: 4, maxWidth: matches ? '60%' : '100%' }}>
            <Grid container spacing={4} justifyContent="center">
                {/* Kachel für Autorinnenfoto */}
                <Grid item xs={12} sm={6} md={12} lg={8}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , backgroundImage: 'linear-gradient(to bottom right, #000000, #14213d)'}}>
                        <CardMedia
                            component="img"
                            height="240"
                            image="/photos/pic2.jpg"
                            alt="Autorin"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign="center">
                                نسرین قربانی
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="center">
                                وبسایت رسمی
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Kachel für Kontakt mit Icon */}
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , backgroundImage: 'linear-gradient(to bottom right, #000000, #14213d)'}}>

                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}> {/* Zentriere Icon und Text */}
                                <ContactMailIcon fontSize="large" />
                                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                                    برقراری ارتباط
                                </Typography>
                                <Typography variant="body2" color="text.secondary" textAlign="center">
                                    آدرس شبکه های اجتماعی
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>

                {/* Kachel für Biographie mit Icon */}
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Link to="/biography" style={{ textDecoration: 'none' }}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , backgroundImage: 'linear-gradient(to bottom right, #000000, #14213d)'}}>

                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}> {/* Zentriere Icon und Text */}
                                <PersonOutlineIcon fontSize="large" />
                                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                                    درباره نویسنده
                                </Typography>
                                <Typography variant="body2" color="text.secondary" textAlign="center">
                                    راجع به من
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                {/* Weitere Kacheln analog, mit Anpassungen für das Responsive Design */}
                {/* Kachel für Bücher */}
                <Grid item xs={12} sm={6} md={12} lg={8}>
                    <Link to="/books" style={{ textDecoration: 'none' }}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , backgroundImage: 'linear-gradient(to bottom right, #000000, #14213d)'}}>

                        <CardMedia
                                component="img"
                                height="240"
                                image="/photos/Books.jpg"
                                alt="Autorin"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                                    کتاب های من
                                </Typography>
                                <Typography variant="body2" color="text.secondary" textAlign="center">
                                    خرید کتاب
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>

            </Grid>
        </Container>
    );
};

export default HomePage;
