import React from 'react';
import { Grid, Card, CardContent, Typography, CardMedia, Container, CardActions, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTheme, useMediaQuery } from '@mui/material';

const books = [
    {
        id: 1,
        name: "بادام های تلخ",
        photoUrl: "/photos/Badaam.jpg",
        description: "نسرین قربانی در توضیح هفتمین رمانش بانام «بادام های تلخ» نیز اظهار کرد: این رمان به مساله نامادری اشاره دارد. چراکه نامادری از گذشته تا به امروز در ذهن ما یک هیولا بوده است اما این موضوع در بسیاری از موارد درست نیست و فقط دیدگاهی است که توسط اطرافیان در ذهن کودک نقش می بندد.\n" +
            "\n" +
            "\n" +
            "وی گفته است: نامادری هم مانند یک مادر وظیفه تربیت فرزندان همسرش را برعهده دارد و گاهی لازم است که برخوردهای جدی با کودک داشته باشد. معمولاً اطرافیان این موضوع را زیادی بزرگ می کنند.",
        purchaseLink: "https://play.google.com/store/books/details?id=GUGxEAAAQBAJ"
    },
    {
        id: 2,
        name: "خانه ای رو به باد",
        photoUrl: "/photos/Khane.jpg",
        description: "نیمه‌ی پاییز درخت‌ها را لاغـر کرده. سکسکه‌ام گرفتـه. یک لیوان نوشابـه می‌ریزم و می‌گذارم هرچه که سر معده‌ام مانده سُر بخورد به طرف پایین. امشب از آن شب‌هایی است که خواب با من سر لج افتاده. نگاهی به تقویم روی اپن می‌اندازم. زمان گذشته و ذهن من توی دو روز پیش متوقف مانده. یادم رفته بود ورق‌ها را با روزها تنظيـم کنم. قرمز نوشته بود: “نیمه‌ی شعبان.” نادی گفتـه بود: “عروسی من و بابات یه همچین روزی بود.” بعدها دیگر تمام تاریخ‌های زندگی توی ذهن‌اش تحلیل رفت. سال‌هاست این ماه‌ها به ندرت به کارم می‌آید. تازه علت تعطیلی بانک و چراغانی‌های امروز را می‌فهمم\n",
        purchaseLink: "https://play.google.com/store/books/details?id=LUGxEAAAQBAJ"
    },
    {
        id: 3,
        name: "نیمه ناتمام",
        photoUrl: "/photos/Nime.jpg",
        description: "«نیمه ناتمام»، چهارمین رمان نسرین قربانی، زندگی خانواده‌ای در دوران قبل و بعد از انقلاب اسلامی را با تمرکز بر مهناز، یکی از اعضا، روایت می‌کند. در طبقه متوسط جامعه قرار دارد، و رمان تحولات شخصیت‌ها پس از انقلاب و تأثیرات آن بر زندگی آنها را به تصویر می‌کشد. تأکید بر تخیلات نویسنده در کنار واقعیت‌های کم از زندگی خود برای ایجاد داستان می‌باشد. داستان با اتفاقاتی تأثیرگذار و تغییرات عمده در سرنوشت شخصیت‌ها، رویارویی‌های جدیدی را معرفی می‌کند.\n",
        purchaseLink: "https://play.google.com/store/books/details?id=O0GxEAAAQBAJ"
    },
    {
        id: 4,
        name: "ویولا",
        photoUrl: "/photos/Viola.jpg",
        description: "ریتم آرام و یک نواخت تقه‌های آقای خوشمرام، مثل ضربه‌های مُرس به دیواره‌ی مغزم می‌کوبد. هزار سال از زمانی گذشته بود که سرحال و قبراق و شق و رق راه می‌رفت. پیری، مثل پازلی هزار قطعه، تکه تکه وجودش را پرکرده بود. سال‌ها گذشته بود از روزی که تا مسافتی، روی زمینی از یخ کشیده شده و از آن به بعد عصا، روی دست‌های پیرش نشسته بود. آبجی اشرف، علی را قُنداق کرده بود:\" این جوری هم پر و پای بچه قرص و محکم می‌مونه هم استخوناش.\"\n",
        purchaseLink: "https://play.google.com/store/books/details?id=evGnEAAAQBAJ"
    },
    {
        id: 5,
        name: " اگر نگویم",
        photoUrl: "/photos/Agar.jpg",
        description: "\"گفتـی: «بـذار آزادیمـو داشـته باشـم پـری. هـر چقـدر دسـت و پـای منـو ببنـدی، همونقـدر ازت دور میشـم.» امـاایـن مـرِز بی تعریـف مثـل معمایـی حل نشـدنی بـود. از گذشـتۀ پایبندیهـات جـدا میشـدی. پوسـت میانداختـی و یکـی دیگـر میشـدی. یکـی کـه میشـناختم و نمیشناختم.میخواسـتم و نمیخواسـتم. حـس میکـردم تـوی خانـهام، خانـهای کـه بـا هـم سـاخته و درسـت کـرده بودیـم، روزهـا و سـالهایی را گذرانـده بودیـم، غریبـهای بـودم که گاهی بـه دیدنت میآیـد و تو گاهی بـه خانـهای سـر مـیزدی کـه هیـچ خاطرهـای از گذشـته های دور در خـود نداشـت و تنهـاازسـِرتکلیـف بـود وشـاید هـم خالی کـردن بار عـذاب وجـدان غریبـه ای بودی کـه\"\n" +
            "\n",
        purchaseLink: "https://play.google.com/store/books/details?id=GF6wEAAAQBAJ"
    },
    {
        id: 6,
        name: "…و نمیدانم",
        photoUrl: "/photos/Nemidanam.jpg",
        description: "رابطه ی پرتلاطم پری و بهرام به دلیل عدم تعهد بهرام و دگرگونی های شخصیتی او رو به زوال است.\n" +
            "\n" +
            "پری خواهان آزادی است، اما بهرام با وابستگی و تعهد او به گذشته، مانع از این امر می شود. بهرام مدام در حال تغییر هویت و شخصیت خود است و این امر پری را از او دور می کند.\n" +
            "\n" +
            "پری در خانه ی مشترکشان احساس غریبی می کند و بهرام نیز به ندرت به آن سر می زند. حساسیت های بهرام افزایش یافته و زخم های روحی او چرکین شده اند.\n" +
            "\n" +
            "پری آرزو می کند که بهرام به همان شخصی که قبلا بود بازگردد، اما او نمی خواهد یا نمی تواند این کار را انجام دهد. پری چشمان خود را شسته و غبار ناخواستن و ندیدن را از آنها زدوده است، اما بهرام همچنان در تاریکی غوطه ور است.",
        purchaseLink: "https://play.google.com/store/books/details?id=MLrHEAAAQBAJ"
    },
];
const BooksPage = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Container sx={{ mt: 4, mb: 4, maxWidth: matches ? '60%' : '100%' }}>
            <Grid container spacing={4} justifyContent="center">
                {books.map((book) => (
                    <Grid item key={book.id} xs={12} sm={6} md={6} lg={4}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <CardMedia
                                component="img"
                                height="250"
                                image={book.photoUrl}
                                alt={book.name}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {book.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {book.description}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing sx={{ justifyContent: 'center', backgroundColor: '#f8f8f8' }}>
                                <IconButton aria-label="buy-book" onClick={() => window.open(book.purchaseLink, '_blank')} sx={{ color: theme.palette.secondary.main }}>
                                    <ShoppingCartIcon />
                                    <Typography sx={{ ml: 1 }}>خرید کتاب</Typography>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default BooksPage;