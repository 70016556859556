import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

const BiographyPage = () => {
    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                درباره نویسنده
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <p>
                                    من، نسرین قربانی. متولد سی تیر ۱۳۳۷، تهران، از نوجوانی گرایش بسیار زیادی نسبت به ادبیات داشتم و با این حال بازرگانی خواندم. چند سالی حسابداری کار کردم و بعد از یک وقفه ی نسبتا طولانی در سال ۱۳۸۲ به مدت چهار سال با روزنامه ی اعتماد کار کردم. سال ۱۳۸۳ جایزه ی بهترین مقاله را از روزنامه ی همشهری گرفتم و مدتی هم با این روزنامه و دیگر روزنامه ها ‌کار کردم. سال ۱۳۸۴ اولین کتابم به نام: خانه ی پدری چاپ شد و سال ۱۳۸۵ مجموعه داستان: من هم انسانم. در بین این سال ها تا ۱۳۸۶ که رمان: فخرالزمان چاپ شد از محضر استادانی چون: محمد بهارلو. سیامک گلشیری و محمد محمد علی بهره بردم. رمان نیمه ی ناتمام، سال ۱۳۹۱ چاپ شد که زیر نظر حسین سناپور بود و اکنون چاپ سوم است. سال ۱۳۹۳ رمان؛ ویولا چاپ شد. سال ۱۳۹۴ در یک فاصله ی کوتاه، دو رمان از دو نشر مختلف چاپ شد، مردی که دوست می داشتم، کتابسرای تندیس و بادام های تلخ: برکه ی خورشید. اردیبهشت ۱۳۹۵ مجموعه داستان: انگار صدایم کرده بودی چاپ شد و شهریور ۱۳۹۸ رمان: اگر نگویم.
                                    از سال ۱۳۹۲ تا به حال داور جایزه ی ادبی صادق هدایت هستم. سه شماره هم با مجله ی تجربه کار کردم.
                                    بان ۱۴۰۰ رمان: خانه ای رو به باد از انتشارات: کتابسرای تندیس چاپ شد.
                                    تابستان ۱۴۰۲ رمان: .... و نمی دانم، از نشر: لوح فکر چاپ شد.
                                </p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default BiographyPage;


